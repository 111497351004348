const resource = "/articles";

export default (axios) => ({
  store(payload = {}, headers = {}) {
    return axios.post(resource, payload, headers);
  },

  update(id, payload = {}, headers = {}) {
    return axios.post(`${resource}/${id}`, payload, headers);
  },

  destroy(id, payload = {}) {
    return axios.post(`${resource}/${id}`, payload);
  },

  find(slug, params = {}) {
    return axios.get(`${resource}/${slug}`, { params });
  },
});
