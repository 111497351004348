import Login from "@/views/Login.vue";
import guest from "@/middlewares/guest";
import auth from "@/middlewares/auth";

const routes = [
  {
    path: "/",
    name: "Login",
    meta: {
      middleware: guest,
      layout: "EmptyLayout",
    },
    component: Login,
  },
  {
    path: "/content-manager",
    name: "ContentManager",
    component: () =>
      import(
        /* webpackChunkName: "content-manager" */ "@/views/ContentManager.vue"
      ),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/content-manager/create",
    name: "ContentManagerCreate",
    component: () =>
      import(
        /* webpackChunkName: "content-manager-create" */ "@/views/ContentManagerCreate.vue"
      ),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/content-manager/:article",
    name: "ContentManagerEdit",
    component: () =>
      import(
        /* webpackChunkName: "content-manager-edit" */ "@/views/ContentManagerEdit.vue"
      ),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "*",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
    meta: {
      layout: "EmptyLayout",
    },
  },
];

export default routes;
