import Vue from "vue";
import {
  Button,
  Dialog,
  Autocomplete,
  Datepicker,
  Field,
  Input,
  Icon,
  Loading,
  Message,
  Table,
  Tabs,
  Toast,
} from "buefy";

Vue.use(Button);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Datepicker);
Vue.use(Field);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Message);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Toast);
