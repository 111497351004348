<template>
  <validation-provider
    :name="$attrs.name"
    :rules="rules"
    slim
  >
    <b-field
      slot-scope="{ errors, valid }"
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': valid }"
      :message="errors"
    >
      <b-input
        :value="value"
        v-bind="$attrs"
        @input="handleChange"
      />
    </b-field>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import "@/common/vee-validate-rules";

export default {
  name: 'InputWithValidation',

  components: {
    ValidationProvider,
  },

  props: {
    value: {
      type: null,
      default: null,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
  },

  methods: {
    handleChange(value) {
      this.$emit("input", value);
    },
  }
};
</script>
