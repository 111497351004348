import axios from "axios";
import store from "@/store";
import router from "@/router";

const baseURL = process.env.VUE_APP_API_URL || "https://api.sunseekerduo.com";

const instance = axios.create({
  baseURL,
  headers: {
    'Accept': 'application/json',
    ...(process.env.VUE_APP_API_TOKEN && { 'Authorization': `Bearer ${process.env.VUE_APP_API_TOKEN}` }),
  },
});

instance.defaults.withCredentials = true;

instance.interceptors.response.use(response => response.data, async error => {
  if (router.currentRoute.name !== 'Login' && error.response.status === 401) {
    await store.dispatch('auth/logOut').finally(() => {
      router.push({ 
        name: 'Login',
      });
    });
    return Promise.reject({
      message: 'Your session has timed out',
    });
  }
  return Promise.reject(error);
});

export default instance;
