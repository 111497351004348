<template>
  <section class="hero is-link is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="box">
            <validation-observer 
              v-slot="{ handleSubmit }"
              ref="form"
              tag="form"
            >
              <input-with-validation
                v-model="form.email"
                name="email"
                rules="required|email"
                label="Email"
                type="email"
                icon="email"
              />
              <input-with-validation
                v-model="form.password"
                name="password"
                rules="required"
                label="Password"
                type="password"
                icon="lock"
                password-reveal
                @keyup.native.enter="handleSubmit(login)"
              />
              <b-button
                expanded
                class="button is-primary"
                :loading="loading"
                @click="handleSubmit(login)"
              >
                Sign in
              </b-button>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import InputWithValidation from "@/components/Validation/InputWithValidation";

export default {
  name: 'Login',

  components: {
    ValidationObserver,
    InputWithValidation,
  },

  data() {
    return {
      loading: false,
      form: {
        email: '',
        password: '',
        remember: true,
      },
    };
  },

  methods: {
    ...mapActions('auth', {
      loginAction: 'login',
    }),

    login() {
      this.loading = true;
      this.loginAction({
        email: this.form.email,
        password: this.form.password,
        remember: this.remember,
      }).then(() => {
        this.$router.push({ name: 'ContentManager' });
      }).catch(err => {
        if (err.response) {
          this.$refs.form.setErrors({
            password: err.response.data.message,
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
