export default (axios) => ({
  csrf() {
    return axios.get("/sanctum/csrf-cookie");
  },

  login(params = {}) {
    return axios.post("/admin/auth/login", params);
  },

  logout() {
    return axios.post("/admin/auth/logout");
  },
});
