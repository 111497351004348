const state = () => ({
  sidebarExpanded: false,
});

const getters = {
  sidebarExpanded(state) {
    return state.sidebarExpanded;
  },
};

const mutations = {
  SET_SIDEBAR_EXPANDED(state, value) {
    state.sidebarExpanded = value;
  },
};

const actions = {
  toggleSidebar({ commit, state }) {
    document.documentElement.classList.toggle('has-aside-mobile-expanded');
    commit('SET_SIDEBAR_EXPANDED', !state.sidebarExpanded);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
