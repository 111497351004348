import axios from "@/common/axios";
import AuthRepository from "./AuthRepository";
import SitemapRepository from "./SitemapRepository";
import ArticleRepository from "./ArticleRepository";
import PurgeRepository from "./PurgeRepository";

const repositories = {
  auth: AuthRepository,
  sitemap: SitemapRepository,
  articles: ArticleRepository,
  purge: PurgeRepository,
};

export default {
  get: (name) => repositories[name](axios),
};
