import Repository from "@/repositories/Repository";

const SitemapRepository = Repository.get("sitemap");
const ArticlesRepository = Repository.get("articles");

const state = () => ({
  articles: [],
});

const getters = {
  articles(state) {
    return state.articles;
  },

  findArticlesByWildcard: (state) => (search) => {
    return state.articles.filter(
      (article) =>
        article.title.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  },
};

const mutations = {
  SET_ARTICLES(state, value) {
    state.articles = value;
  },

  ADD_ARTICLE(state, { id, title, published_at }) {
    state.articles.push({
      id,
      title,
      published_at,
    });
  },

  EDIT_ARTICLE(state, { id, title, published_at }) {
    const article = state.articles.find((article) => article.id === id);
    Object.assign(article, {
      title,
      published_at,
    });
  },

  DELETE_ARTICLE(state, value) {
    const index = state.articles.findIndex((article) => article.id == value);
    state.articles.splice(index, 1);
  },
};

const actions = {
  async fetchArticles({ commit }) {
    const response = await SitemapRepository.articles({
      state: "preview",
    });
    commit("SET_ARTICLES", response.data);
  },

  async createArticle({ commit }, payload) {
    return ArticlesRepository.store(payload).then((response) => {
      commit("ADD_ARTICLE", response.data);
      return response;
    });
  },

  async editArticle({ commit }, payload) {
    return ArticlesRepository.update(payload.id, payload).then((response) => {
      commit("EDIT_ARTICLE", response.data);
      return response;
    });
  },

  async deleteArticle({ commit }, payload) {
    return ArticlesRepository.destroy(payload.id, payload).then((response) => {
      commit("DELETE_ARTICLE", payload.id);
      return response;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
