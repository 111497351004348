import { required, email, max } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "This {_field_} field is required.",
});

extend("email", {
  ...email,
  message: "The {_field_} must be a valid email.",
});

extend("max", {
  ...max,
  message: "The {_field_} may not be greater than {length} characters.",
});

extend("slug", {
  validate: value => {
    const regex = new RegExp(/^[a-z0-9]+(?:-[a-z0-9]+)*$/);
    return regex.test(value);
  },
  message: "The field must be a valid URL slug.",
});
